import { useState, useEffect } from "react";
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './home.css';
import { Link } from "react-router-dom";

const Home = () => {
    const [, setSelectedText] = useState("AllProducts");
    const [users, setUsers] = useState([]);

    const userRef = collection(db, "display")

    useEffect(() => {

        const getUsers = async () => {
            const data = await getDocs(userRef);
            setUsers(data.docs.map((doc => ({ ...doc.data(), id: doc.id }))));


        }

        getUsers()
    }, [userRef])

    const handleElementClick = (event) => {
        const clickedText = event.target.textContent;
        setSelectedText(clickedText);
        window.open(`/product/${clickedText}`, "_self");
    };

    return (
        <>

            <div className="Section_one" >
                {users && users.map((user) => {
                    return (
                        <>
                            <img id="img_src" className="home_img_holder" src={user.product_img} alt="" />
                            <Link to={`/products/${user.name}`} style={{ textDecoration: 'none' }}>
                                <div className="button_holder" >
                                    <button>Click Here</button>
                                </div>
                            </Link>

                        </>
                    )
                })}

                {users && users.map((user) => {
                    return (
                        <>

                            <div className="modal_container_home">
                                <div className="modal_container_img_home">
                                    <img id="img_src" src={user.product_img} alt="" />
                                </div>
                                <div className="modal_container_text_home">
                                    <img id='modal_container_bg_img_home' src={user.bg_img_link} alt="" />
                                    <h3 id='product_name_home'>{user.name}</h3>
                                    <h3 id="heading_one">{user.heading_one}</h3>
                                    <h3 id="heading_two">{user.heading_two}</h3>
                                    <h3>NUTRITION FACTS</h3>
                                    <img id='table_img_home' src={user.table_text} alt="" />
                                    <p id="table_para_one">{user.table_para_one}</p>
                                    <p id="table_para_two">{user.table_para_two}</p>
                                    <p id="table_para_three">{user.table_para_three}</p>
                                    <h3 id="heading_three">{user.heading_three}</h3>
                                    <p id="heading_three_para">{user.heading_three_para}</p>
                                    <h3 id="heading_four">{user.heading_four}</h3>
                                    <p id="heading_four_para">{user.heading_four_para}</p>
                                    <h3 id="heading_five">{user.heading_five}</h3>
                                    <p id="heading_five_para">{user.heading_five_para}</p>
                                    <p id="heading_six">{user.heading_six}</p>
                                    <p id="sub_heading_one">{user.sub_heading_one}</p>
                                    <p id="sub_heading_two">{user.sub_heading_two}</p>
                                    <p id="sub_heading_three">{user.sub_heading_three}</p>
                                    <p id="sub_heading_four">{user.sub_heading_four}</p>
                                    <p id="sub_heading_five">{user.sub_heading_five}</p>
                                    <p id="sub_heading_six">{user.sub_heading_six}</p>
                                    <p id="sub_heading_seven">{user.sub_heading_seven}</p>
                                    <p id="quantity">{user.quantity}</p>
                                    <h3 id="why_best">{user.why_best}</h3>
                                    <p id="para_one">{user.para_one}</p>
                                    <p id="para_two">{user.para_two}</p>
                                    <p id="para_three">{user.para_three}</p>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
            <div className="Section_two">
                <div className="grid_text"><h2>Product Categories</h2></div>
                <div className="grid_layout_wrapper" id="home" >
                    <div className="gird" onClick={handleElementClick}>All Products</div>
                    <div className="gird" onClick={handleElementClick}>Nutrition For Kids</div>
                    <div className="gird" onClick={handleElementClick}>Women Health</div>
                    <div className="gird" onClick={handleElementClick}>Nutricosmetics</div>
                </div>
            </div>
            <div className="Section_three">
                <div className="Section_three_img_holder">
                    <img src="https://img.freepik.com/free-vector/healthy-nutrition-dietitian-recommendation-flat-composition-with-body-mass-index-control-vegetables-salmon-eggs-supplements_1284-60046.jpg?w=740&t=st=1693001021~exp=1693001621~hmac=97957597dd7309ee0fe38ceb8c3ca0407036de9eed33a0836f1ff32811cb498b" alt="Card" />
                </div>
                <div className="Section_three_text_wrapper">
                    <h1>WHO WE ARE</h1>
                    <h2>SIMPLIFYING WELLNESS - ENERGIZING LIVES </h2>
                    <h3>We believe that right nutrition brings out the best in us.
                        Simplifying the path towards right nutrition, fundamentally lies at
                        the core of what we at AVANOM consistently focus upon. To know more about our story and core beliefs...
                    </h3>
                    <Link to="about_us">
                        <button onClick={scrollToTop}>Click Here</button>
                    </Link>
                </div>
            </div>
        </>
    )
    function scrollToTop() {
        window.scrollTo(0, 0);
    }


}

export default Home;