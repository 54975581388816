import React from 'react'
import './Product_showcase.css';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust the import based on your file structure




const Product_showcase = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const userRef = collection(db, "users");
        const data = await getDocs(userRef);
        const users = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        console.log("Fetched users:", users); // Debugging line
        const selectedProduct = users.find(user => user.id === productId);
        console.log("Selected product:", selectedProduct); // Debugging line
        setProduct(selectedProduct);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product data:", error);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  if (loading) return <div>Loading...</div>;

  if (!product) {
    console.log("Product not found: ", productId); // Debugging line
    return <div>Product not found</div>;
  }

  console.log("Rendering product: ", product); // Debugging line
  return (
    <>
      <div className='Main_container'>
        <div className="content_wrapper">
          <div className="content_left">
            <h1>{product.name}</h1>
            <img src={product.product_img} alt={product.name} />
          </div>
          <div className="content_right">
            <p>{product.description}</p>
            <h1>Nutrition Facts</h1>
            <img src={product.table_text} alt="Nutrition Facts" />
            <p>{product.table_para_one}</p>
            <p>{product.table_para_two}</p>
            <p>{product.table_para_three}</p>
            <h3>Details</h3>
            <p>{product.heading_one}</p>
            <p>{product.heading_two}</p>
            <p>{product.heading_three}</p>
            <p>{product.para_one}</p>
            <p>{product.para_two}</p>
            <p>{product.para_three}</p>
          </div>

        </div>



      </div>
    </>

  )
}

export default Product_showcase