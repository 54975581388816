import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './componets/Navbar/navbar';
import Home from './componets/Home/home';
import Products from './componets/Products/Product_display';
import AboutUs from './componets/About_us/about_us';
import Footer from './componets/Footer/footer';
import ContactUs from './componets/Contact_us/contact_us';
import ProductShowcase from './componets/Products/Product_showcase';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route index element={<Home />} />
          <Route path='/About_Us' element={<AboutUs />} />
          <Route path='/product/:parameter' element={<Products />} />
          <Route path='/Contact_Us' element={<ContactUs />} />
          <Route path='/products/:productId' element={<ProductShowcase />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
